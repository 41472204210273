// 引入
@import "./_include/_include.sass";

// 引入
@import "./_include/_include.sass";

.seese-mainmenu ul li:hover ul {
  padding-top: 20px;
  padding-bottom: 20px; }


.seese-mainmenu ul li ul li {
  &:last-of-type {
    padding-bottom: 0; } }

.seese-topright {
  display: none; }

.seese-header .seese-logo.site-logo {
  margin: 0;
  padding: 10px 0;
  width: 310px;
  min-width: 0;
  max-width: 310px;
  @include media_max(1140px) {
    max-width: 56px; }
  @include media_max(1000px) {
    max-width: 45px; }
  @include smLpad {
    max-width: 310px; }
  a {
    display: flex;
    align-items: center;
    p {
      padding: 0;
      @include media_max(1140px) {
        display: none; }
      @include smLpad {
        display: block; } }
    img {
      display: block;
      margin-right: 13px;
      @include size(56px,70px);
      @include media_max(1140px) {
        margin-right: 0; }
      @include media_max(1000px) {
        @include size(45px,56px); }
      @include smLpad {
        margin-right: 15px; } }
    span {
      display: block;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 2px;
      text-align: center;
      @include cr($clr-1);
      @include smLpad {
        font-size: 18px;
        text-align: left; }
      @include min {
        font-size: 14px; }
      &+span {
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0px;
        @include smLpad {
          font-size: 12px; } } } } }

.seese-header {
  .row {
    margin: 0; }
  .container {
    @include container {
      padding: 0;
      max-width: 1280px;
      width: calc(100% - 80px);
      @include media_max(1000px) {
        width: calc(100% - 60px); }
      @include media_max(767px) {
        padding: 0; } } }
  .seese-mainmenu.seese-mainmenu {
    width: calc(100% - 310px);
    padding: 0;
    @include clearfix;
    @include media_max(1140px) {
      width: calc(100% - 56px); }
    @include media_max(1000px) {
      width: calc(100% - 45px); }
    li {
      padding: 0;
      a {
        display: block;
        @include cr($clr-1);
        font-weight: normal; }
      &:hover {
        background-color: $clr-1;
        &>a {
          @include cr(#fff); } } }
    &>ul {
      float: right;
      margin-right: -12px;
      &>li {
        margin-right: 0;
        &>a {
          padding: 32.5px 10px;
          font-size: 18px;
          letter-spacing: 1px;
          @include media_max(1200px) {
            letter-spacing: 1px; }
          @include media_max(1000px) {
            font-size: 16px;
            padding: 27px 5px; } }
        ul {
          background-color: rgba(#fff,.85);
          top: 100%;
          width: 100%;
          padding: 0;
          box-shadow: 0 5px 5px #e2e2e2;
          li {
            a {
              padding: 10px 5px;
              font-size: 14px;
              letter-spacing: 1px;
              text-align: center;
              @include cr($clr-1);
              @include media_max(1000px) {
                font-size: 12px; } } } } } } } }

#seese-mobilemenu {
  width: 100%;
  .slicknav_menu .slicknav_btn {
    margin: 0;
    top: 28px;
    right: 30px; }

  .slicknav_nav.slicknav_nav {
    padding: 15px 0;
    margin: 0;
    box-shadow: none;
    background-color: $clr-1;
    li {
      margin: 0;
      border: none;
      background: none; }
    &>li {
      &>a {
        padding: 10px 30px; }
      &>a,&>a>a {
        font-size: 16px;
        @include cr(#fff); } }
    .sub-menu.row {
      background-color: #fff;
      padding: 10px 30px;
      a {
        padding: 10px 0;
        font-size: 16px;
        @include cr($clr-1); } } } }
