// 引入
@import "./_include/_include.sass";

body {
  animation: fade .5s 1s both; }

@mixin idxhide {
  &>.kc-row-container.kc-container {
    visibility: hidden;
    @content; } }

@mixin h6_t {
  display: flex;
  align-items: center;
  z-index: 2;
  span {
    font-size: 16px;
    font-weight: bold;
    @include cr(#757575);
    font-family: serif;
    opacity: 0;
    @include t(.5s);
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        transition-delay: #{($i - 1)*0.5}s; } }
    @include smLphone {
      font-size: 14px; }
    &:nth-of-type(2) {
      display: block;
      margin: 0 15px;
      opacity: 1;
      @include size(0px,1px);
      // +size(50px,1px)
      background-color: #757575; } }
  &.active {
    span {
      opacity: 1;
      &:nth-of-type(2) {
        @include size(50px,1px); } } }
  &.abs {
    position: absolute;
    left: 5%;
    top: 50px;
    transform-origin: left bottom;
    transform: rotate(90deg);
    @include media_max(1200px) {
      left: 5px; } } }

@mixin h2_t {
  margin-bottom: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 4px;
  &:after {
    content: "";
    display: block;
    @include size(40px,3px);
    background-color: $clr-4;
    margin: 10px auto 0; }
  @content; }



#idx-carousel {
  @include idxhide;
  img {
    display: block;
    width: 100%; }
  .content {
    position: absolute;
    bottom: 50%;
    left: 10%;
    transform: translateY(50%);
    h3 {
      margin: 0;
      font-size: 55px;
      @include cr(#fff);
      font-weight: bold;
      line-height: 82px;
      letter-spacing: 8px;
      @include media_max(1000px) {
        font-size: 30px;
        line-height: 50px; }
      @include smLpad {
        font-size: 20px;
        line-height: 30px; }
      @include min {
        font-size: 18px;
        line-height: 25px; } }
    h4 {
      padding: 5px 8px;
      margin: 15px 0 0;
      background-color: $clr-1;
      @include cr(#fff);
      font-size: 20px;
      letter-spacing: 4px;
      @include media_max(1000px) {
        font-size: 16px; }
      @include smLpad {
        font-size: 14px;
        margin: 10px 0 0; }
      @include min {
        font-size: 12px;
        margin: 5px 0 0; }
      &:first-of-type {
        margin-top: 30px;
        @include media_max(1000px) {
          margin-top: 15px; }
        @include min {
          margin-top: 10px; } } }
    * {
      opacity: 0;
      transform: translateX(50px);
      @include t(); }
    @for $i from 1 through 100 {
      *:nth-child(#{$i}) {
        transition-delay: #{1.5+0.1*$i}s; } } }
  .swiper-slide {
    overflow: hidden;
    &:after,&:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      @include size(50%,100%);
      transform: skew(-10deg);
      background-color: rgba(#444444,.5);
      @include t(1s); }
    &:before {
      // left: -27%
      left: -100%; }
    &:after {
      // right: -48%
      right: -100%; }
    &.swiper-slide-active {
      .content {
        * {
          transform: translateX(0px);
          opacity: 1; } }
      &:after,&:before {
        transition-delay: .4s; }
      &:before {
        left: -27%; }
      &:after {
        right: -48%; } } } }


#follow-row {
  padding: 30px 0;
  background-image: url('/wp-content/uploads/background.png');
  background-size: cover;
  .kc_text_block {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include clearfix;
    @include container {
      float: none;
      padding: 0;
      @include smLpad {
        padding: 0; } }
    h4 {
      margin: 0;
      width: 50%;
      font-weight: normal;
      letter-spacing: 1,8px;
      @include cr($clr-3);
      font-size: 18px;
      white-space: nowrap;
      @include smLphone {
        font-size: 16px; }
      @include min {
        font-size: 12px; }
      strong {
        display: block;
        font-family: serif;
        margin-bottom: 10px;
        @include cr($clr-1); } }
    ul {
      display: flex;
      justify-content: flex-end;
      width: 50%;
      li {
        list-style-type: none;
        @include t(.3s);
        margin-left: 68px;
        @include smLphone {
          margin-left: 30px; }
        &:first-of-type {
          margin-left: 0; }
        a,img {
          display: block;
          margin: 0;
          @include size(auto,35px);
          @include smLphone {
            @include size(auto,25px); } }
        &:hover {
          transform: scale(1.3); } } } } }

[id^="idx-about"] {
  position: relative;
  @include smLphone;
  .kc-wrap-columns {
    display: flex;
    @include smLphone {
      display: block; }
    .kc_single_image {
      height: 100%;
      position: relative;
      // overflow: hidden
      @include smLphone {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }
    img {
      // object-fit: cover
      // +size(auto,100%)
      max-width: 100%;
      @include media_max(1270px) {
        position: absolute;
        top: 0;
        left: 0;
        max-width: fit-content;
        @include size(auto,100%); }
      @include biGpad {
        transform: translateX(-25%); }
      @include smLphone {
        transform: none;
        position: relative;
        @include size(100%,100%);
        object-fit: cover; } }
    &>div {
      @include smLphone {
        position: static; } }
    &>div:last-of-type {
      @include smLphone {
        .kc_text_block {
          background-color: rgba(#fff,.9);
          padding: 30px;
          box-shadow: 2px 2px 5px #bbb;
          @include min {
            padding: 20px; } } }
      &:before {
        content: "";
        display: block;
        position: absolute;
        right: -94%;
        top: 0;
        @include size(200%,100%);
        background-color: #fff;
        transform: skew(-10deg);
        @include smLphone {
          display: none; } }
      .kc-col-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 13vw 40px 7vw;
        position: relative;
        z-index: 2;
        @include biGpad {
          padding-right: 30px;
          padding-left: 50px; }
        @include smLphone {
          padding: 40px 30px; }
        @include min {
          padding: 20px; } }
      h6 {
        margin-bottom: 65px;
        @include h6_t;
        @include smLphone {
           margin-bottom: 40px; } }
      h2 {
        margin-bottom: 40px;
        font-size: 20px;
        letter-spacing: 4px;
        font-weight: bold;
        @include cr($clr-3);
        @include smLphone {
          font-size: 18px; }
        &:after {
          content: "";
          display: block;
          margin-top: 15px;
          @include size(40px,3px);
          background-color: $clr-4; } }
      h3 {
        font-size: 18px;
        letter-spacing: 1.8px;
        margin-bottom: 20px;
        font-weight: normal;
        @include cr($clr-1);
        @include smLpad {
          font-size: 16px; } }
      p {
        padding: 0;
        margin: 0;
        font-size: 16px;
        letter-spacing: 1.6px;
        line-height: 27px;
        font-weight: normal;
        @include cr($clr-3);
        @include smLpad {
          letter-spacing: 1px; }
        @include smLphone {
          font-size: 14px; } }
      em {
        display: block;
        font-style: inherit;
        margin-top: 50px;
        @include smLpad {
          margin-top: 30px; }
        a {
          font-size: 18px;
          @include cr($clr-1);
          @include smLpad {
            font-size: 14px; }
          &:after {
            content: ">";
            margin-left: 10px; } } } } } }

// news
#idx-news {
  background-image: url('/wp-content/uploads/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center center;
  @include idxhide;
  h6.abs {
    @include h6_t; }

  h2 {
    @include h2_t; }
  a.more {
    display: table;
    margin: 35px auto 0;
    font-size: 18px;
    @include cr($clr-5); }
  .container {
    @include container; }
  .news-row {
    padding: 0 20px 20px;
    background-color: #fff;
    .item {
      display: flex;
      align-items: center;
      padding: 20px 50px;
      border-top: solid 5px transparent;
      border-bottom: solid 1px #b6b6b6;
      @include t(.3s);
      @include biGpad {
        flex-wrap: wrap;
        align-items: flex-end; }
      @include smLphone {
        padding-left: 20px;
        padding-right: 20px; }
      @include min {
        padding-left: 10px;
        padding-right: 10px; }
      &:hover {
        background-color: #F1F1F1;
        border-top-color: $clr-1;
        h3 a {
          @include cr($clr-1);
          &:after,&:before {
            right: -20px;
            background-color: $clr-1; } } }
      span,h3 {
        display: block;
        margin: 0;
        font-size: 18px;
        letter-spacing: 1.8px;
        line-height: 36px;
        font-weight: normal;
        @include biGpad {
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 1px; }
        @include smLphone {
          font-size: 14px; } }

      span.date {
        width: 165px;
        @include cr($clr-1);
        @include biGpad {
          width: auto;
          white-space: nowrap; } }
      span.cate {
        text-align: center;
        width: 120px;
        margin-right: 30px;
        padding: 0 20px;
        background-color: $clr-1;
        @include cr(#fff);
        @include biGpad {
          background-color: transparent;
          @include cr($clr-3);
          font-size: 14px;
          padding-left: 0;
          &:before {
            content: "-";
            display: inline-block;
            margin-right: 5px; } } }
      h3 {
        width: calc(100% - 315px);
        @include biGpad {
          width: 100%;
          padding-top: 10px; }
        a {
          display: block;
          padding-right: 50px;
          @include cr($clr-3);
          position: relative;
          @include smLphone {
            padding-right: 0px; }
          &:after,&:before {
            content: "";
            display: block;
            height: 2px;
            background-color: $clr-3;
            position: absolute;
            bottom: calc(50% - 1px);
            right: 0;
            @include t(.3s);
            @include smLphone {
              display: none; } }
          &:after {
            width: 20px; }
          &:before {
            width: 10px;
            transform-origin: right top;
            transform: rotate(45deg); } } } } } }


#idx-record {
  position: relative;
  background-image: url('/wp-content/uploads/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include idxhide;
  .container {
    @include container; }
  .carousel {
    padding: 40px 30px 0px;
    background-color: #fff;
    position: relative; }
  h2 {
    @include h2_t; }
  h6.abs {
    @include h6_t; }
  .swiper-slide {
    width: calc(100% / 3 - 0.5px);
    padding: 0 6px;
    @include smLphone {
      width: 50%; }
    @include media_max(500px) {
      width: 100%; }
    h3,p,img {
      padding: 0;
      margin: 0; }
    h3 {
      a {
        display: block;
        position: relative;
        &:after {
          display: flex;
          align-items: center;
          justify-content: center;
          content: "More";
          position: absolute;
          background-color: rgba($clr-1,.5);
          @include cr(#fff);
          @include t(.3s);
          opacity: 0;
          top: 0;
          left: 0;
          @include size(100%); } } }
    a {
      display: block;
      line-height: 50px;
      font-size: 18px;
      letter-spacing: 1.8px;
      text-align: center;
      font-weight: normal;
      @include cr($clr-3);
      @include smLphone {
        font-size: 14px; } }
    &:hover h3 a:after {
      opacity: 1; } }
  .swiper-button-next, .swiper-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    margin-top: 0;
    top: 40px;
    width: 25px;
    height: calc(100% - 90px);
    @include cr(#fff);
    background-color: $clr-3;
    font-size: 30px;
    line-height: auto;
    @include smLphone {
      font-size: 20px; }
    &:hover {
      background-color: $clr-1;
      @include cr(#fff); } }
  .swiper-button-next {
    right: 0; }
  .swiper-button-prev {
    left: 0; } }


.swiper-pagination {
  padding-top: 35px;
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @include smLphone {
    padding-top: 25px; }
  span {
    margin: 0 8px;
    @include size(8px);
    background-color: $clr-5;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background-color: $clr-1; } } }

#idx-link {
  position: relative;
  @include idxhide;
  h6 {
    @include h6_t; }
  h2 {
    @include h2_t; }
  .container {
    @include container {
      float: none; } }

  .swiper-slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      padding: 20px 30px;
      width: calc(100% / 3 - 8.5px);
      background-color: #F2F1EE;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 1.8px;
      border-bottom: solid 2px #C9C9C9;
      position: relative;
      font-weight: normal;
      @include cr($clr-3);
      @include biGpad {
        width: calc(50% - 8.5px); }
      @include smLpad {
        font-size: 16px; }
      @include smLphone {
        padding: 30px;
        width: 100%;
        font-size: 14px;
        margin-bottom: 15px;
        &:last-of-type {
          margin-bottom: 0; } }
      &:before {
        content: "";
        display: block;
        margin-right: 16px;
        @include size(20px);
        background-image: url('/wp-content/uploads/icon_logo.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center; }
      img {
        display: block;
        margin: 0 0 0 16px;
        @include size(42px,auto);
        @include smLpad {
          position: absolute;
          margin: 0;
          right: calc(50% - 21px);
          bottom: calc(50% - 21px);
          opacity: 0.2; } } } } }
