// 引入
@import "./_include/_include.sass";

@mixin page_bg {
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-image: url('/wp-content/uploads/background-1.png');
    background-size: cover;
    opacity: 0.03;
    pointer-events: none; }
  &>* {
    position: relative;
    z-index: 2; }
  @content; }

@mixin download_btn {
  position: relative;
  padding-right: 60px;
  &:after {
    content: "";
    display: block;
    @include size(25px,30px);
    background-image: url('/wp-content/uploads/download_icon.svg');
    background-size: cover;
    position: absolute;
    bottom: calc(50% - 15px);
    right: 0; }
  @content; }

@mixin top_nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 65px;
  @include smLphone {
    padding-bottom: 30px; }
  li,&>* {
    font-size: 0;
    margin: 0 20px;
    @include smLphone {
      margin: 0 10px; }
    a {
      display: block;
      padding-bottom: 5px;
      padding-left: 0;
      padding-right: 0;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 3px;
      border-bottom: solid 3px transparent;
      font-weight: bold;
      text-align: center;
      @include t(.3s);
      @include cr($clr-1);
      @include biGpad {
        font-size: 16px; }
      @include smLphone {
        font-size: 14px;
        border-bottom-width: 2px;
        letter-spacing: 0;
        white-space: nowrap; } }
    &:hover>a,&.current-cat>a,&.ui-tabs-active>a {
      border-bottom-color: $clr-1; }
    @content; } }

#page-banner {
  position: relative;
  &+.seese-title-area {
    display: none;
    @include size(0);
    overflow: hidden; }
  img {
    width: 100%; }

  p {
    width: auto;
    margin: 0;
    padding: 20px 60px;
    position: absolute;
    z-index: 10;
    right: 50%;
    bottom: 50%;
    transform: translate(50%,50%);
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 3px;
    font-weight: bold;
    background-color: rgba($clr-1,.6);
    white-space: nowrap;
    @include cr(#fff);
    @include biGpad {
      padding: 10px 20px;
      font-size: 20px; }
    @include min {
      font-size: 16px; } } }

[id^="page-"][id^="page-"] {
  .kc-wrap-columns,.row {
    margin: 0;
    width: 100%;
    @include clearfix; }
  .kc-container {
    padding: 0; } }


[id^="page-about"] {
  .flex {
    display: flex;
    flex-wrap: wrap; }
  .menu {
    width: 170px;
    @include smLpad {
      display: none; }
    ul {
      li {
        margin: 0 0 10px;
        list-style-type: none;
        &:last-of-type {
          margin-bottom: 0; }
        a {
          display: block;
          padding: 15px 0;
          text-align: center;
          font-size: 20px;
          letter-spacing: 3px;
          @include cr($clr-1);
          font-weight: bold;
          background-color: $clr-6;
          @include biGpad {
            font-size: 16px;
            padding: 10px 0; } }
        &:hover,&.current-menu-item {
          a {
            background-color: $clr-1;
            @include cr(#fff); } } } } }
  .contents {
    padding-left: 50px;
    width: calc(100% - 170px);
    @include biGpad {
      padding-left: 20px; }
    @include smLpad {
      padding-left: 0;
      width: 100%; }
    h3 {
      margin: 0 0 35px;
      @include cr($clr-1);
      font-size: 25px;
      letter-spacing: 3px;
      line-height: 35px;
      @include biGpad {
        margin-bottom: 20px;
        line-height: 25px;
        font-size: 18px; }
      img {
        margin: 0;
        float: right;
        @include smLpad {
          float: none;
          margin-top: 15px; } } }
    h4 {
      margin-bottom: 7px;
      font-size: 20px;
      letter-spacing: 3px;
      @include biGpad {
        font-size: 16px; } }
    h5 {
      @include cr($clr-1);
      font-size: 20px;
      letter-spacing: 3px;
      @include biGpad {
        font-size: 18px; }
      @include smLphone {
        font-size: 16px;
        letter-spacing: 1px; } }
    p,li,a {
      padding: 0;
      font-size: 20px;
      line-height: 35px;
      letter-spacing: 3px;
      @include cr(#000);
      @include biGpad {
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 1px; }
      @include smLphone {
        font-size: 14px; } }
    a:hover {
      @include cr($clr-1); }
    p,ul {
      &+h3 {
        margin-top: 40px;
        @include biGpad {
          margin-top: 30px; } }
      &+h4,&+h5 {
        margin-top: 30px;
        @include biGpad {
          margin-top: 20px; } } } }

  .inpage {
    display: none;
    padding: 40px 50px;
    background-color: $clr-6;
    box-shadow: 5px 3px 6px #00000029;
    @include biGpad {
      padding: 30px; }
    &.active {
      display: block;
      animation: fade 1s both; } }

  .flex.people {
    padding-top: 40px;
    margin-left: -20px;
    width: calc(100% + 40px);
    .kc_text_block {
      padding: 0 20px;
      margin-bottom: 20px;
      img {
        display: block;
        width: 100%;
        margin: 0 0 10px;
        padding: 0;
        @include smLpad {
          max-width: 400px;
          margin: 0 auto 10px; } }
      p,a {
        display: block;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        @include biGpad {
          font-size: 14px;
          white-space: nowrap; }
        @include smLpad {
          font-size: 16px; } } }
    .kc-col-inner-container {
      @include smLpad {
        // display: flex
        // flex-wrap: wrap
        .kc_text_block {
 } } } } }          // width: 50%

// 簡介
#page-about-info {
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-image: url('/wp-content/uploads/background-1.png');
    background-size: cover;
    opacity: 0.03;
    pointer-events: none; }
  &>div:not(.container) {
    visibility: hidden; }
  .container {
    position: relative;
    z-index: 2;
    @include container; } }


.btns {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 30px;
  @include smLphone {
    padding: 30px 0; }
  &>span {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    margin: 0 20px;
    background-color: $clr-1;
    font-size: 16px;
    letter-spacing: 1.6px;
    @include cr(#fff);
    cursor: pointer;
    @include smLphone {
      font-size: 14px;
      letter-spacing: 1px; }
    &.next:after {
      content: "";
      display: block;
      margin-left: 10px;
      @include size(0);
      border-left: solid 8px #fff;
      border-top: solid 4px transparent;
      border-bottom: solid 4px transparent; }
    &.prev:before {
      content: "";
      display: block;
      margin-right: 10px;
      @include size(0);
      border-right: solid 8px #fff;
      border-top: solid 4px transparent;
      border-bottom: solid 4px transparent; } } }

// 最新公告、活動訊息
#page-post,#page-active {
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-image: url('/wp-content/uploads/background-1.png');
    background-size: cover;
    opacity: 0.03;
    pointer-events: none; }
  &>* {
    position: relative;
    z-index: 2; }
  &+div {
    visibility: hidden; }
  ul {
    @include top_nav; }
  .wp-pagenavi {
    padding-bottom: 80px;
    @include smLphone {
      padding-bottom: 40px; }
    span,a {
      margin: 0 10px;
      padding: 5px;
      background-color: transparent;
      border: none;
      border-bottom: solid 1px transparent;
      @include cr(#000);
      font-size: 20px;
      @include biGpad {
        font-size: 16px; }
      &.current,&:hover {
        @include cr($clr-1);
        border-bottom-color: $clr-1; } } } }

#page-post {
  ul {
    @include container; }
  .wrapper {
    background-color: #F4F4F4;
    margin-bottom: 70px;
    padding: 30px 0 40px;
    @include smLphone {
      padding: 10px 0 30px;
      margin-bottom: 30px; } }
  .post-row {
    @include container;
    padding: 0!important; }
  .item {
    border-bottom: dashed 1px #D8D8D8;
    a {
      display: flex;
      align-items: center;
      padding: 24px 15px;
      line-height: 30px;
      font-size: 24px;
      letter-spacing: 2.4px;
      @include cr(#000);
      @include biGpad {
        font-size: 20px; }
      @include smLphone {
        padding: 15px 10px;
        font-size: 14px;
        display: block;
        letter-spacing: 1px; }
      span {
        display: block;
        min-width: 200px;
        max-width: 200px;
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        @include cr($clr-1);
        @include biGpad {
          font-size: 16px;
          min-width: 150px;
          max-width: 150px; }
        @include smLphone {
          font-size: 14px; } } } } }

// 活動媒體
#page-active {
  .container {
    @include container; }
  .active-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    width: calc(100% + 20px);
    @include smLphone {
      margin-left: 0;
      width: 100%; } }
  .item {
    margin-bottom: 25px;
    padding: 0 10px;
    width: calc(100% / 3 - 0.5px);
    @include smLpad {
      width: calc(50% - 0.5px); }
    @include smLphone {
      width: 100%;
      padding: 0;
      &:last-of-type {
        margin-bottom: 0; } }
    img {
      display: block;
      border: solid 5px transparent;
      @include t(.3s); }
    h3 {
      margin-top: 20px;
      @include cr(#000);
      font-size: 20px;
      letter-spacing: 3px;
      text-align: center;
      @include smLphone {
        font-size: 14px; } }
    &:hover {
      img {
        border-color: $clr-1; } } } }

// 運動紀錄
#page-record {
  @include page_bg;
  &>div {
    @include container {
      float: none; } }
  .kc_text_block {
    padding-bottom: 110px;
    @include smLpad {
      padding-bottom: 80px; }
    @include smLphone {
      padding-bottom: 40px; }
    h3 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 20px;
      font-size: 30px;
      letter-spacing: 3px;
      font-weight: bold;
      @include cr($clr-1);
      @include smLpad {
        font-size: 20px;
        padding-bottom: 0; }
      @include smLphone {
        font-size: 18px; }
      img {
        margin: 0 30px 0 0;
        @include size(auto,100px);
        @include smLpad {
          @include size(auto,50px);
          margin-right: 20px; } } }
    p {
      padding: 20px 30px;
      background-color: #EFEFEF;
      @include smLpad {
        padding: 20px; }
      &:nth-of-type(even) {
        background-color: #F7F7F7; }
      a {
        display: block;
        position: relative;
        padding-right: 60px;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 2px;
        @include cr(#000);
        @include smLpad {
          font-size: 16px; }
        @include smLphone {
          font-size: 14px; }
        @include download_btn; } } } }

// 法令規章
#page-rule {
  @include page_bg;
  &>.kc-row-container.kc-container {
    @include container; }
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    @include top_nav; }
  li {
    list-style-type: none;
    a {
      background: none; }
    &:hover {
      background: none; } }
  .kc_tab_content {
    padding: 0;
    @include clearfix; }
  .kc_tabs_nav~.kc_tab.ui-tabs-body-active {
    background: none; }
  .kc_text_block {
    display: flex;
    flex-wrap: wrap; }
  p {
    display: flex;
    align-items: center;
    width: 50%;
    @include smLpad {
      width: 100%; }
    figure {
      display: block;
      margin-right: 40px;
      @include biGpad {
        margin-right: 30px; }
      img {
        display: block;
        @include size(auto,70px); } }
    .content {
      display: flex;
      width: 100%;
      flex-wrap: wrap; }
    a {
      display: flex;
      align-items: center;
      width: 50%;
      padding: 10px 0;
      @include cr(#000);
      font-size: 20px;
      @include download_btn {
        padding-right: 0;
        &:after {
          margin-left: 20px;
          position: static; } }
      @include biGpad {
        font-size: 16px; }
      @include smLphone {
        font-size: 14px; } } } }

// 運動規則
#page-legal {
  @include page_bg;
  &>.kc-row-container.kc-container {
    @include container; }
  h3 {
    @include cr($clr-1);
    font-size: 30px;
    letter-spacing: 4.5px;
    margin: 0 0 30px;
    @include smLpad {
      font-size: 25px;
      letter-spacing: 3px; }
    @include smLphone {
      font-size: 20px;
      letter-spacing: 2px; } }
  p {
    padding-bottom: 20px;
    @include smLpad {
      padding-bottom: 15px; }
    @include smLphone {
      padding-bottom: 10px; }
    a {
      display: block;
      @include cr(#000);
      font-size: 20px;
      letter-spacing: 3px;
      @include download_btn;
      @include smLpad {
        font-size: 16px;
        letter-spacing: 1px; }
      @include smLphone {
        font-size: 14px; } }
    &+h3 {
      margin-top: 40px;
      @include smLpad {
        margin-top: 30px; }
      @include smLphone {
        margin-top: 20px; } } } }

// 相關連結
#page-links {
  @include page_bg;
  &>.kc-row-container.kc-container {
    @include container; }
  .kc_text_block {
    display: flex;
    flex-wrap: wrap;
    @include smLpad {
      margin-left: -15px;
      width: calc(100% + 30px); } }
  h3 {
    width: 33.3%;
    margin-bottom: 65px;
    padding: 0 15px;
    @include smLpad {
      width: 50%; }
    @include smLphone {
      margin-bottom: 40px; }
    a {
      display: block;
      text-align: center;
      @include cr(#000);
      font-size: 20px;
      letter-spacing: 2px;
      line-height: 30px;
      @include smLpad {
        font-size: 16px;
        line-height: 28px; }
      @include smLphone {
        font-size: 14px; } }
    img {
      display: block;
      margin: 0 auto 30px;
      width: 100%;
      max-width: 250px;
      @include smLphone {
        max-width: 70%; } } } }

// 聯絡我們
#page-contact {
  &>.kc-row-container.kc-container {
    @include container; }

  .kc_text_block {
    background-image: url('/wp-content/uploads/background_contact.svg');
    background-repeat: no-repeat;
    background-position: center 0%;
    background-size: contain;
    &.contact {
      padding: 80px 0 100px;
      text-align: center;
      @include smLpad {
        padding: 50px 0 80px; }
      h2 {
        @include cr($clr-1);
        margin: 0;
        font-size: 30px;
        letter-spacing: 3px;
        @include smLpad {
          font-size: 20px;
          letter-spacing: 1px; }
        @include smLphone {
          font-size: 16px; } } }
    &.list {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      hr {
        width: 100%;
        margin: 0;
        opacity: 0; }
      h4,p {
        line-height: 35px;
        margin-bottom: 30px;
        font-size: 20px;
        @include smLpad {
          margin-bottom: 15px;
          line-height: 30px;
          font-size: 16px; }
        @include smLphone {
          font-size: 14px;
          margin-bottom: 10px;
          line-height: 20px; } }
      p {
        padding: 0;
        letter-spacing: 2px;
        @include cr(#000);
        a {
          display: inline-block;
          padding-bottom: 3px;
          line-height: 35px;
          @include cr(#000);
          @include smLpad {
            line-height: 30px; }
          @include smLphone {
            line-height: 20px; } } }
      h4 {
        min-width: 75px;
        width: 75px;
        @include cr($clr-1);
        font-weight: bold;
        letter-spacing: 2px;
        @include smLpad {
          min-width: 65px;
          width: 65px; }
        @include min {
          min-width: 50px;
          width: 50px; } }
      h5 {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        @include cr($clr-1);
        padding-left: 75px;
        line-height: 30px;
        @include smLpad {
          padding-left: 65px;
          font-size: 12px; }
        @include min {
          padding-left: 0; } } } }


  .kc_google_maps {
    margin-bottom: 100px;
    box-shadow: 0px 3px 6px #00000029;
    @include smLphone {
      margin-bottom: 50px; } }

  .kc-google-maps {
    position: relative;
    padding-bottom: 45%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%); } } }

body.single-post {
  #page-post .wrapper {
    margin-bottom: 0;
    .post-row {
      background-color: #fff; }
    .post-heading {
      font-size: 25px;
      margin-bottom: 10px;
      line-height: 35px;
      font-weight: bold;
      @include cr($clr-1);
      @include smLpad {
        font-size: 18px;
        line-height: 30px; } }
    .seese-content-area {
      padding: 40px 30px;
      @media screen and (max-width: 640px) {
        padding: 30px 15px; } }
    .seese-article,.seese-sharebar,.seese-pagination {
      width: 100%;
      max-width: 100%;
      float: left; }
    .seese-article {
      padding: 30px 0; }
    .seese-publish {
      margin-bottom: 30px;
      ul {
        padding: 0;
        justify-content: start;
        width: 100%;
        font-weight: bold;
        @include cr($clr-5);
        li {
          margin: 0;
          display: none;
          &.date {
            display: block;
            font-size: 18px;
            @include smLpad {
              font-size: 16px; } } } } } } }
