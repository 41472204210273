// 引入
@import "./_include/_include.sass";

.seese-footer.seese-footer {
  padding: 50px 0;
  background-color: transparent;
  background-image: url('/wp-content/uploads/home-7.jpg');
  background-size: cover;
  background-position: center center;
  position: relative;
  @include smLpad {
    padding-bottom: 5px; }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    @include size(100%);
    background-color: rgba($clr-2,.66); }
  .container {
    position: relative;
    z-index: 2;
    @include container {
      padding: 0;
      max-width: 1280px;
      width: calc(100% - 120px);
      @include media_max(1000px) {
        width: calc(100% - 60px); }
      @include media_max(767px) {
        padding: 0; } }

    &>.row {
      margin: 0;
      &>div {
        display: flex;
        padding: 0; } } }

  .info {
    width: 370px;
    @include smLpad {
      width: 100%; }
    * {
      @include cr(#fff); }
    h3 {
      margin-bottom: 20px;
      font-size: 20px;
      letter-spacing: 4px;
      line-height: 28px;
      @include smLpad {
        font-size: 18px; } }
    li,p,a {
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
      @include smLphone {
        font-size: 14px; } }
    p.copy {
      @include smLphone {
        margin-top: 30px; }
      a {
        display: block;
        @include smLphone {
          font-size: 12px; } } } }

  .widget_nav_menu {
    margin: 0;
    width: calc(100% - 370px);
    @include media_max(1000px) {
      display: none; }
    .row {
      margin: 0; }

    ul {
      &.main-navigation {
        display: flex;
        justify-content: space-between;
        &>li>a {
          margin-bottom: 30px;
          font-size: 16px; } }
      li {
        display: block;
        padding: 0;
        &:after {
          display: none; }
        a {
          display: block;
          letter-spacing: 1px;
          line-height: 28px;
          @include cr(#fff);
          font-size: 14px; } } } } }
