//color
$clr-1: #063785;
$clr-2: #082451;
$clr-3: #343434;
$clr-4: #AE2222;
$clr-5: #757575;
$clr-6: #EFEFEF;


// RWD
$desktop: 1680px;
$laptop: 1280px;
$smLpad: 767px;
$biGpad: 1000px;
$smLphone: 640px;
$biGphone: 736px;
$min: 414px;

@mixin desktop {
  @media screen and (max-width: $desktop) {
    @content; } }
@mixin laptop {
  @media screen and (max-width: $laptop) {
    @content; } }
@mixin smLpad {
  @media screen and (max-width: $smLpad) {
    @content; } }
@mixin biGpad {
  @media screen and (max-width: $biGpad) {
    @content; } }
@mixin smLphone {
  @media screen and (max-width: $smLphone) {
    @content; } }
@mixin biGphone {
  @media screen and (max-width: $biGphone) {
    @content; } }
@mixin min {
  @media screen and (max-width: $min) {
    @content; } }
@mixin media_max($size) {
  @media screen and (max-width: $size) {
    @content; } }

@mixin media_min($size) {
  @media screen and (min-width: $size) {
    @content; } }


//mixin
@mixin size($w,$h:$w) {
  width: $w;
  height: $h; }

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }

@mixin t($t:0.5s) {
  transition-duration: $t; }

@mixin cr($c:#222) {
  color: $c; }

@mixin border($w:1px,$s:solid,$c:#222) {
  border: $w $s $c; }

@mixin circle($r) {
  width: $r;
  height: $r;
  border-radius: 50%; }

@mixin flex {
  display: flex;
  flex-wrap: wrap; }

@mixin line($s:solid,$w:1px,$c:#e5e5e5) {
  border: $s $w $c; }

@mixin container {
  padding: 80px 0;
  margin: 0 auto;
  width: calc(100% - 60px);
  max-width: calc(1280px - 150px * 2);
  float: none;
  @include media_min(1500px) {
    max-width: 1350px; }
  @include smLpad {
    padding: 40px 0; }
  @content; }
